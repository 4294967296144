import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const PieChart = (props) => {
  const [chartConfig, setChartConfig] = useState({
    series: [],
    options: {}
  });

  useEffect(() => {
    const pieChartLabels = props.chartData.map(item => item.inventoryType);
    const pieChartSeries = props.chartData.map(item => item.spentUsd);
    if (pieChartLabels.every(element => element !== null) && pieChartSeries.every(element => element !== null)) {
    const pieChart = {
      series: pieChartSeries,
      options: {
        dataLabels: {
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
          }
        },
        legend: {
          position: 'left',
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 600,
          markers: {
            size: 10,
          },
          itemMargin: {
            vertical: 8,
        },
        },
        labels: pieChartLabels,
        colors: ["#717fff", "#f978c8"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom',
            }
          }
        }],
        tooltip: {
          y: {
            formatter: function (value) {
              return value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            }
          }
        }
      },
    };
    setChartConfig(pieChart);
  }
  },[props.chartData]);
  
  return (
    chartConfig.series.length == 0 ? (
      <div className="noDataFound newHomePieChart">
        <p className="newHomeRevenueChartText">No data available</p>
      </div>
    ) : (
      <Chart
        options={chartConfig.options}
        series={chartConfig.series}
        type="pie"
        width="445"
      />
    )
  )
}

export default PieChart;