const i18n = {
  // Advance Reports
  advanceReportsAdvertiser: 'Advertiser',
  advanceReportsAdvertiserSaas: 'Advertiser Saas',
  advanceReportsCampaign: 'Campaign',
  advanceReportsCampaignSaas: 'Campaign Saas',
  advanceReportsClient: 'Client',
  advanceReportsCampaignId: 'Campaign Id',
  advanceReportsLineItem: 'Line Item',
  advanceReportsLineItemSaas: 'Line Item Saas',  
  advanceReportsCreative: 'Creative',
  advanceReportsLineItemId: 'Line Item Id',
  advanceReportsCreativeId: 'Creative Id',
  advanceReportsFormat: 'Format',
  advanceReportsDate: 'Date',
  advanceReportsOfferType: 'Offer Type',
  advanceReportsInventoryType: 'Inventory Type',
  advanceReportsCountry: 'Country',
  advanceReportsCountryApp: 'Country App',
  advanceReportsCountryYoutube: 'Country Youtube',
  advanceReportsDevices: 'Devices',
  advanceReportsAppName: 'App Name',
  advanceReportsYoutubeChannel: 'Youtube Channel',
  advanceReportsIDHSDeal: 'ID HS Deal',
  advanceReportsImpressions: 'Impressions',
  advanceReportsViews: 'Views',
  advanceReportsGoal: 'Goal',
  advanceReportsClicks: 'Clicks',
  advanceReportsEngagements: 'Engagements',
  advanceReportsVTR: 'VTR',
  advanceReportsCTR: 'CTR',
  advanceReportsER: 'ER',
  advanceReportsInversionBudgetCurrency: 'Inversion budget Currency',
  advanceReportsInversionBudgetUSD: 'Inversion budget USD',
  advanceReportsSpentUSD: 'Spent (USD)',
  advanceReportsCurrency: 'Currency',
  advanceReportsSpentCurrencyDeal: 'Spent Currency Deal',
  advanceReportsStartDate: 'Start Date',
  advanceReportsEndDate: 'End Date',
  advanceReportsQuartiles: 'Quartiles',
  advanceReportsFirstQuartile: 'First Quartile',
  advanceReportsMidQuartile: 'Mid Quartile',
  advanceReportsThirdQuartile: 'Third Quartile',
  advanceReportsCompletedViews: 'Completed Views',
  advanceReportsMarket: 'Market',
  advanceReportsBillingStatus: 'Billing Status',
  advanceReportsEmpty: 'Your report is currently empty.',
  advanceReportsGetStarted: 'To get started, drag and drop metrics dimensions in the relevant section.',
  advanceReportsNoMatches: 'No matches for your filters',
  advanceReportsNoDataFound: 'No data found',
  advanceReportsSelectItems: 'Select or search the items with which you want to build the report',
  advanceReportsCpm: 'CPM Usd',
  advanceReportsCpv: 'CPV Usd',
  advanceReportsCpmCurrency: 'CPM Currency',
  advanceReportsCpvCurrency: 'CPV Currency',
  advanceReportsReportName: 'Report name',
  advanceReportsLastModified: 'Last modified',
  advanceReportsReportId: 'Report Id',
  advanceReportsViewsInfoMessage: 'Views for In Stream Skippable are Trueviews. For Bumper Ads, In Stream non Skippable, Branded Video and Pre Roll they are Completed Views',
  advanceReportsViewsExclamationMessage: 'Views reported are only from In stream skippable format. Not views available for non skippable formats',
  advanceReportsMonth: 'Month',
  advanceReportsVtrInfoMessage: 'VTR is based on completed views except for In-Stream Skippable that is based in True views',
  advanceReportsPlacementsInfoMessage: `Some placements with fewer than 100 impressions may be missing from the report:
  - The placements report currently only shows placements with significant activity.
  - Not all placements are displayed, and it’s advisable to rely on the general report to get the total number of impressions.`,
  performanceReport: 'Performance Report',
  audienceReport: 'Audience Report',
  myReports: 'My Reports',
  advanceReportsInfoUserMessage: 'Quartiles and Devices variables have available information since July 24, 2024. To consult information prior to that date, please contact your account manager',


  add: 'Add',
  app: 'Apps',
  youtube: 'Youtube',
  apply: 'Apply',
  cancel: 'Cancel',
  row: 'Row',
  column: 'Column',
  rows: 'Rows',
  columns: 'Columns',
  clearAll: 'Clear all',
  execute: 'Execute',
  enableExecuteButton: 'To enable you need to select a Date Range and have at least one Row selected',
  save: 'Save',
  saveAsNew: 'Save As New',

  // Dates
  dateRange: 'Date range',
  yesterday: 'Yesterday',
  today: 'Today',
  thisMonth: 'This month',
  lastSevenDays: 'Last seven days',
  lastMonth: 'Last month',
  customDate: 'Custom date',


  // New Home
  activeSeatsDynamic: 'Dynamic seats active ',
  activeCampaignsDynamic: 'Active campaigns dynamic',
  activeLineitemsDynamic: 'Active Line items Dynamic',
  revenueDynamic: 'Revenue dynamic',
  activeMediaPlanFp: 'FP active media plans',
  activeCampaigns: 'Active campaigns',
  activeLineitems: 'Active Line Items',
  revenueFp: 'Revenue FP',

}

export default i18n;