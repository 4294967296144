export const countries = [
  {"id":0,"name":"All"},
  {"id":2,"name":"Argentina"},
  {"id":3,"name":"Bolivia"},
  {"id":4,"name":"Brasil"},
  {"id":5,"name":"Chile"},
  {"id":6,"name":"Colombia"},
  {"id":7,"name":"Costa Rica"},
  {"id":8,"name":"Ecuador"},
  {"id":9,"name":"El Salvador"},
  {"id":10,"name":"Guatemala"},
  {"id":11,"name":"Honduras"},
  {"id":12,"name":"Mexico"},
  {"id":13,"name":"Nicaragua"},
  {"id":14,"name":"Panama"},
  {"id":15,"name":"Paraguay"},
  {"id":16,"name":"Peru"},
  {"id":17,"name":"Puerto Rico"},
  {"id":18,"name":"Dominican Republic"},
  {"id":19,"name":"Uruguay"},
  {"id":20,"name":"Venezuela"},
  {"id":21,"name":"United States"},
  {"id":22,"name":"Czech Republic"},
  {"id":23,"name":"Slovakia"},
  {"id":24,"name":"Poland"},
  {"id":25,"name":"Lithuania"},
  {"id":26,"name":"Latvia"},
  {"id":27,"name":"Hungary"},
  {"id":28,"name":"Turkey"},
  {"id":29,"name":"Zambia"},
  {"id":30,"name":"South Africa"},
  {"id":31,"name":"United Arab Emirates"},
  {"id":32,"name":"Romania"},
  {"id":33,"name":"Greece"},
  {"id":34,"name":"Spain"},
  {"id":35,"name":"Portugal"},
  {"id":36,"name":"United Kingdom"},
  {"id":37,"name":"Australia"},
  {"id":38,"name":"Japan"},
  {"id":39,"name":"Austria"},
  {"id":40,"name":"Belgium"},
  {"id":41,"name":"Bulgaria"},
  {"id":42,"name":"Croatia"},
  {"id":43,"name":"Denmark"},
  {"id":44,"name":"Egypt"},
  {"id":45,"name":"Estonia"},
  {"id":46,"name":"Finland"},
  {"id":47,"name":"France"},
  {"id":48,"name":"Germany"},
  {"id":49,"name":"India"},
  {"id":50,"name":"Indonesia"},
  {"id":51,"name":"Ireland"},
  {"id":52,"name":"Israel"},
  {"id":53,"name":"Italy"},
  {"id":54,"name":"South Korea"},
  {"id":55,"name":"Netherlands"},
  {"id":56,"name":"New Zealand"},
  {"id":57,"name":"Norway"},
  {"id":58,"name":"Philippines"}
]
