import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const NewHomeBarChart = (props) => {
  const [chartConfig, setChartConfig] = useState({
    series: [],
    options: {}
  });

  useEffect(() => {
    const columnChart = {
      series: [
        {
          name: 'Impressions',
          data: props.impressionsChart
        }, 
        {
          name: 'Views',
          data: props.viewsChart
        }
      ],
      options: {
        colors: ['#f978c8', '#00eacb'],
        legend: {
          position: "top",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 700,
          markers: {
            size: 7,
            shape: 'circle',
            offsetX: -3,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 10,
            vertical: 0
        },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '65%',
            borderRadius: 5,
            borderRadiusApplication: 'end',
            borderRadiusWhenStacked: 'last',
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: props.selectedDays,
          labels: {
            formatter: (value) => value,
            style: {
              color: '#242833',
              fontSize: '12px',
              fontFamily: 'Montserrat',
            },
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return new Intl.NumberFormat('en-US').format(value);
            },
            style: {
              color: '#242833',
              fontSize: '12px',
              fontFamily: 'Montserrat',
            },
          }
        },
        chart: {
          toolbar: {
            tools: {
              download: false
            }
          }
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return new Intl.NumberFormat('en-US').format(value);
            },
          },
        }
      }
    };
    
    setChartConfig(columnChart);
  },[props.impressionsChart, props.viewsChart]);

  return (
    <Chart
      options={chartConfig.options}
      series={chartConfig.series}
      type="bar"
      height="350"
    />
  )
} 

export default NewHomeBarChart;