const baseURL = process.env.REACT_APP_DOT_NET_BASE_URL;

export const getDashboardNewHome = async (period, startDate, endDate, countryId, inventory, advertisers, clients, model) => {
  try {
    const raw = JSON.stringify({
      period: period,
      startDate: startDate,
      endDate: endDate,
      countryId: countryId,
      inventory_type: inventory,
      advertiserId: advertisers,
      clients: clients,
      model: model
    });

    const response = await fetch(`${baseURL}report/dashboardnewhome`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};